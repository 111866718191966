export function initializeHtmlElement(htmlElement: HTMLElement, template: string, styles: CSSStyleSheet[] = [], elementIdsToHideUntilStylesLoad: string[] = [], delegatesFocus = false) {
    const shadowRoot = htmlElement.attachShadow({ mode: "open", delegatesFocus });
    shadowRoot.innerHTML = template;
    const hiddenElements = elementIdsToHideUntilStylesLoad.map(x => shadowRoot.getElementById(x)).filter(x => x !== null);
    if (styles.length > 0)
        installStyles(shadowRoot, styles, hiddenElements);
}

export function installStyles(documentOrDocumentFragment: DocumentOrShadowRoot, styles: CSSStyleSheet[], hiddenElements: HTMLElement[]) {
    hiddenElements.forEach(x => x.style.visibility = "hidden");
    let stylesLoaded = 0;
    const styleId = "font-face-styles";
    let fontFaceStyle = document.getElementById(styleId);
    if (!fontFaceStyle) {
        const elem = document.createElement("style");
        elem.id = styleId;
        document.getElementsByTagName('head')[0].appendChild(elem);
        fontFaceStyle = elem;
    }
    styles.forEach((style) => {
        // https://stackoverflow.com/a/63717709
        // Take care of @font-face styles
        Array.from(style.cssRules).filter(x => x.constructor.name === CSSFontFaceRule.name).forEach(cssRule => {
            if (!fontFaceStyle.textContent?.includes(cssRule.cssText))
                fontFaceStyle.appendChild(document.createTextNode(cssRule.cssText));
        });
        documentOrDocumentFragment.adoptedStyleSheets.push(style);

        // Make the hidden elements visible once all styles are loaded
        stylesLoaded++;
        if (stylesLoaded === styles.length)
            hiddenElements.forEach(x => x.style.visibility = "");
    });
}