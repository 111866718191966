import bootstrap from '../scss/bootstrap-custom.scss';
import template from './ActionButton.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { nullThrow } from './TypeScriptFunctions';

export class ActionButton extends HTMLElement {
    private _buttonText: Element | undefined;
    private _spinner: Element;
    private _shouldShowSpinner: boolean;
    private _alternateButtonText: string | null;
    private _originalButtonText: string | null = null;
    private _originalInnerHtml: string = '';

    constructor() {
        super();
        // allow shadow dom disable for bootstrap table buttons
        if (this._shadowDisabled) {
            const buttonText = this.textContent;
            this.innerHTML = template;
            this._buttonText = nullThrow(this.querySelector('#button-text'));
            this._buttonText.textContent = buttonText;
            this._spinner = nullThrow(this.querySelector('#spinner'));
        } else {
            initializeHtmlElement(this, template, [bootstrap]);
            this._spinner = nullThrow(this.shadowRoot?.querySelector('#spinner'));
        }

        this._shouldShowSpinner = false;
        this._alternateButtonText = this.getAttribute('alternatebuttontext');
        this._onClick = this._onClick.bind(this);
        this.addEventListener('click', this._onClick);
    }

    static get observedAttributes() {
        return ['alternatebuttontext', 'disabled'];
    }

    attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
        if (oldValue === newValue)
            return;

        if (name === 'alternatebuttontext') {
            this._alternateButtonText = newValue;
        }

        if (name === 'disabled') {
            if (newValue === null) {
                this._enableButton();
            } else {
                this._disableButton();
            }

            this._shouldShowSpinner = false;
        }
    }

    _enableButton() {
        this._spinner.toggleAttribute('hidden', true);
        if (this._shadowDisabled) {
            this._buttonText!.textContent = this._originalButtonText;
        } else if (this._alternateButtonText) {
            this.innerHTML = this._originalInnerHtml;
        }
        this.classList.remove('disabled');
        this.style.pointerEvents = 'auto';
    }

    _disableButton() {
        this._spinner.toggleAttribute('hidden', !this._shouldShowSpinner);
        this.classList.add('disabled');
        this.style.pointerEvents = 'none';
        this._originalInnerHtml = this.innerHTML;
        this._originalButtonText = this.textContent;
        if (this._shouldShowSpinner && this._alternateButtonText !== null && this._alternateButtonText !== undefined) {
            const hasText = this._alternateButtonText !== "";
            this._spinner.classList.toggle("mr-2", hasText); // Prevents off-center spinner
            if (this._shadowDisabled) {
                this._originalButtonText = this._buttonText!.textContent;
                this._buttonText!.textContent = this._alternateButtonText;
            } else {
                this.textContent = this._alternateButtonText;
            }
        }
    }

    // Override for some scenarios where user input is required after button click like for file inputs.
    setSpinner(show: boolean) {
        this._spinner.toggleAttribute('hidden', !show);
    }

    set disabled(value) {
        this.toggleAttribute('disabled', value);
    }

    get disabled() {
        return this.hasAttribute('disabled');
    }

    get _shadowDisabled() {
        return this.hasAttribute('data-shadow-disabled');
    }

    _onClick(event: Event) {
        if (this.disabled) {
            event.stopImmediatePropagation();
        }
        this._shouldShowSpinner = true;
        setTimeout(() => this._shouldShowSpinner = false, 250);
    }
}
customElements.define('action-button', ActionButton);