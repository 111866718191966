import bootstrap from '../scss/bootstrap-custom.scss';
import template from './StateDropdown.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { nullThrow } from "./TypeScriptFunctions";

// How to make a custom element form associated
// https://web.dev/more-capable-form-controls/
// https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements-face-example

export class StateDropdown extends HTMLElement {
    private _selectElement: HTMLSelectElement;
    private _formGroup: HTMLElement;
    private _internals: ElementInternals;
    constructor() {
        super();
        initializeHtmlElement(this, template, [bootstrap], [], true);
        
        this._selectElement = nullThrow(this.shadowRoot?.querySelector('select'));
        this._formGroup = nullThrow(this.shadowRoot?.getElementById('form-group'));

        this._internals = this.attachInternals();
        this._selectElementOnChange = this._selectElementOnChange.bind(this);
        this._dispatchChangeEvent = this._dispatchChangeEvent.bind(this);
    }

    static get observedAttributes() {
        return ['required'];
    }

    static get formAssociated() {
        return true;
    }

    connectedCallback() {
        this._selectElement.addEventListener('change', this._selectElementOnChange);
    }

    disconnectedCallback() {
        this._selectElement.removeEventListener('change', this._selectElementOnChange);
    }

    attributeChangedCallback(name: string | null, oldValue: string | null, newValue: string | null) {
        if (oldValue === newValue)
            return;

        if (name === 'required') {
            this._formGroup.classList.toggle('required', newValue !== null);
            this._selectElement.toggleAttribute('required', newValue !== null);
            this._setValidity();
        }
    }

    set value(value) {
        this._selectElement.value = value;
        this._setValidity();
    }

    get value() {
        return this._selectElement.value;
    }

    get options() {
        return this._selectElement.options;
    }

    set selectedIndex(value) {
        this._selectElement.selectedIndex = value;
        this._setValidity();
    }

    get selectedIndex() {
        return this._selectElement.selectedIndex;
    }

    get _isRequired() {
        return this._selectElement.hasAttribute('required');
    }

    _selectElementOnChange() {
        this._setValidity();
        this._dispatchChangeEvent();
    }

    _setValidity() {
        this._internals.setValidity(this._selectElement.validity, this._selectElement.validationMessage, this._selectElement);
    }

    _dispatchChangeEvent() {
        const event = new CustomEvent("change", { detail: { state: this._selectElement.value } });
        this.dispatchEvent(event);
    }
}

customElements.define('state-dropdown', StateDropdown);