import fontawesome from '@fortawesome/fontawesome-free/css/all.css'
import template from './CustomTooltip.html';
import { initializeHtmlElement } from './HTMLElementExtensions';
import { nullThrow } from './TypeScriptFunctions';

export class CustomTooltip extends HTMLElement {
    private _tooltip: HTMLElement;

    constructor() {
        super();
        initializeHtmlElement(this, template, [fontawesome]);
        this._tooltip = nullThrow(this.shadowRoot?.getElementById('tooltip'));
    }

    set hidden(value: boolean) {
        this.toggleAttribute('hidden', value);
    }

    set title(value: string) {
        this._tooltip.title = value;
        $(this._tooltip).tooltip();
    }
}
customElements.define('custom-tooltip', CustomTooltip);
